import { AllContracts } from "../components";

const Home = () => {
  return (
    <div className="container">
      <AllContracts />
    </div>
  );
};

export default Home;
